<template>
  <div v-if="loggedIn !== null && loggedIn !== undefined">
    <div v-if="userName === '' || userName === null">
      <b-modal size="m" tabindex="-1" role="dialog" no-close-on-esc no-close-on-backdrop hide-header-close  v-model="showModal" aria-hidden="true" hide-footer >
        <p><b>Willkommen</b></p>
        <p>Damit das Programm richtig funktioniert, wird dein Vor- und Nachname benötigt.</p>
        <p>Bitte trage deinen Vor- und Nachnamen in das Feld ein und Speichere die einstellung.</p>
        <form autocomplete="off">
          <input type="text" class="form-control" id="name" v-model="userNameInput" placeholder="Vorname Nachname"> 
        </form>  
        <b-button  variant="outline-primary" @click="saveMyName()" block class="m-0 mt-3"><font-awesome-icon :icon="['fas', 'save']" /> Speichern</b-button>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { auth } from "../plugins/firebase";
import { onAuthStateChanged, updateProfile } from "firebase/auth";

export default {
name: 'CheckUserName',
computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  data(){
    return{
      loggedIn: undefined,
      userName: undefined,
      showModal: true,
      userNameInput: null
    }
  },
  methods:{
    saveMyName(){
      this.$store.state.user.data.displayName = this.userNameInput
      console.log(auth)
      updateProfile(auth.currentUser, { 
        displayName: this.userNameInput
      }).then(()=>{
        this.showModal = false
      })
      .catch(err=>{
        console.log(auth)
        console.log(err)
        })
    },
  },
  created: function () {
    var $this = this
    onAuthStateChanged(auth, function(user) {
      if (user && !$this.loggedIn) {
        $this.loggedIn = user
        $this.userName = user.displayName
      } 
    })
  }
};
</script>
<style>

</style>