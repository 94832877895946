<template>
  <div>
    <img v-if="path" :src="path" class="img-fluid" id="personImage">
  </div>
</template>

<script>
import { storage } from "../plugins/firebase"
import { ref as storageRef, getBlob } from "firebase/storage"

export default {
  name: 'Applications',
  data(){
    return{
      path: null,
    }
  },
  props:{
    data: Object
  },
  methods: {
    async getImage(path){
      if (typeof path === 'object'){
        await getBlob(storageRef(storage, path.path))
        .then(blob=>{
          console.log('StorageBlob',blob)
          this.path = URL.createObjectURL(blob)
        })
      } else {
        this.path = path
      }
      console.log(this.path)
      return
    }
  },
  mounted(){
    this.getImage(this.data.image)
  }
}
</script>

<style scoped>
  #personImage { 
    position: absolute; 
    right: 0px; 
    top: 0px;  
    padding: 5px; 
    border: 1px solid #d3d3d3; 
    border-radius: 5px; 
    margin: 16px; 
    max-width: 240px;
  }
</style>
