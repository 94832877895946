<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light mb-4 bg-primary">
      <div class="container">
      <router-link class="navbar-brand d-flex" style="color: white;" to="/">
        <Logo :fill="'#ffffff'" :height="'33px'" :width="'33px'" class="mb-0 mr-2 d-flex align-items-center"></Logo>
        Bewerberportal
      </router-link>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto ml-3">
            <template v-if="user">
              <b-nav-item-dropdown text="Bewerbungen" right>
                <b-dropdown-item to="/bewerbungen">Eingang</b-dropdown-item>
                <b-dropdown-item to="/archiv">Archiv</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item>
                <router-link to="/stellenangebote">Stellenangebote</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/specialresponse">Abwesenheitsnotiz</router-link>
              </b-nav-item>
            </template>
          </ul>
        </div>  
        <div>
          <template v-if="user">
            <a class="nav-link" @click.prevent="signOutNow"><font-awesome-icon :icon="['fas', 'sign-out']" /></a>
          </template>
        </div>
      </div>
    </nav>
     <!-- <CheckUserName/> -->
  </div>
</template>
<script>
import { auth } from "../plugins/firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";

import Logo from '../assets/Logo'
// import CheckUserName from "./CheckUserName"

export default {
  name: 'Navbar',
  data(){
    return {
      user: null
    }
  },
  components: {
    Logo,
  },
  methods: {
    signOutNow() {
        signOut(auth)
        .then(() => {
          this.$router.replace({
            name: "login"
          });
        });
    }
  },
  beforeMount() {
    onAuthStateChanged(auth, (user)=>{
      // console.log(user)
      this.user = user
    })
  }
};
</script>
<style>
.navbar { 
  /* background-color:#007bff !important; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.navbar a.nav-link,
.navbar li a {
  cursor: pointer;
  color:rgb(255, 255, 255) !important;
}
.navbar a.nav-link:hover,
.navbar li a:hover {
  color:rgb(225, 225, 225)!important;
}
.navbar ul li ul li a {
  color: #3d3d3d !important;
}
.navbar ul li ul li a:hover {
    color: #676767 !important;
}
.navbar ul li ul li a:active {
    color: #fefefe !important;
}
</style>