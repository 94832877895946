<template>
  <div style="display: inline-block;" v-if="data">

    <b-dropdown text="Weitere Anlagen" class="m-md-2" variant="outline-dark" v-if="data[0].url">
      <b-dropdown-item v-for="(item, index) in data" :key="index" :href="item.url" target="_blank">
        <font-awesome-icon :icon="['fas', 'download']" />
          {{item.name}}  
      </b-dropdown-item>
    </b-dropdown>

    <b-dropdown text="Weitere Anlagen" class="m-md-2" variant="outline-dark" v-else>
      <b-dropdown-item v-for="(item, index) in blobs" :key="index" @click="download(item)" href="#" target="_blank">
        <font-awesome-icon :icon="['fas', 'download']" />
          {{item.name}}  
      </b-dropdown-item>
    </b-dropdown>

  </div>
</template>

<script>
import { storage } from "../plugins/firebase"
import { ref as storageRef, getBlob } from "firebase/storage"

export default {
  name: 'Applications',
  data(){
    return{
      blobs: []
    }
  },
  props:{
    data: Array
  },
  methods: {
    async getBlobs(path){
      this.urls = []
      this.blobs = []
      console.log(path)
      if (path[0].path){
        for await (const ref of path) {
          console.log(ref)
          await getBlob(storageRef(storage, ref.path))
          .then(blob=>{
            this.blobs.push({name: ref.name, blob: blob})
          })
        }
      }
      return
    },
    download(data){
      let link = document.createElement('a');
      link.download = data.name;
      link.href = URL.createObjectURL(data.blob);
      link.click();
      URL.revokeObjectURL(link.href);
    }
  },
  mounted(){
    this.getBlobs(this.data)
  }
}
</script>

<style scoped>
  #personImage { 
    position: absolute; 
    right: 0px; 
    top: 0px;  
    padding: 5px; 
    border: 1px solid #d3d3d3; 
    border-radius: 5px; 
    margin: 16px; 
    width: 240px;
  }
</style>
