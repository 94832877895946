import Vue from 'vue';
import Login from '../components/Login'
import JobOffers from '../components/JobOffers'
import Applications from '../components/Applications'
import Archive from '../components/Archive'
import NotAllowed from '../components/NotAllowedView'
import SpecialResponse from "../components/SpecialResponse"

import Router from 'vue-router';
import {auth} from '../plugins/firebase'


Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { 
            path: '*',
            redirect: '/login' 
        },
        { 
            path: '/', 
            redirect: '/login' 
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/token/:token",
            component: Login,
            name: "Token",
            meta: {
              requiresAuth: false,
            }, 
        },
        {
            path: '/notallowed',
            name: 'NotAllowed',
            component: NotAllowed,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/stellenangebote',
            name: 'jobOffers',
            component: JobOffers,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/bewerbungen',
            name: 'applications',
            component: Applications,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/specialresponse',
            name: 'specialresponse',
            component: SpecialResponse,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/archiv',
            name: 'archive',
            component: Archive,
            meta: {
                requiresAuth: true
            }
        }
    ]
});

router.beforeEach(async (to, from, next) => {
    const currentUser = await auth.currentUser
    const getIdTokenResult = currentUser ? await currentUser.getIdTokenResult(): false
    const isUser = getIdTokenResult ? getIdTokenResult.claims.user : false
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

    if ((to.name === 'login'|| to.name === 'NotAllowed') && currentUser && isUser) next({ name: 'applications' })
    else if (to.name !== 'NotAllowed' && !isUser && currentUser && requiresAuth) next({ name: 'NotAllowed' })
    else if (requiresAuth && currentUser) next()
    else if (requiresAuth && !currentUser) next({ name: 'login' })
    else next()
})

export default router