<template>
  <div style="margin: 0;" id="app">
    <navbar></navbar>
      <router-view></router-view>
      <CheckUserName/>
  </div>
</template>
<script>
import navbar from "./components/Navbar";
import CheckUserName from "./components/CheckUserName";

export default {
  components: {
    navbar,
    CheckUserName
  }
};
</script>