<template v-if="this.user.loggedIn">
  <div class="container">
    <div v-if="message.success !== undefined">
      <div class="alert alert-success fixed-top text-center" role="alert">
        {{message.success}}
      </div>
    </div>
    <div v-if="message.error !== undefined">
      <div class="alert alert-danger fixed-top text-center" role="alert">
        {{message.error}}
      </div>
    </div>
    <p>Die abwesenheits Notiz wird zusätzlich in der Eingangsbestätigung angezeigt.</p>
    <div class="card p-2">
      <div class="p-3 text-center">
        <p>Abwesenheitsnotiz</p>
        <b-form-textarea
          id="textarea"
          v-model="text"
          placeholder="Zusätlicher Text zur Eingangsbesätigung..."
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </div>
      <div style="display:flex; justify-content: space-around;" class="text-center">
        <div>
          <label for="startDate">Start Datum</label><br>
          <b-calendar name="startDate" v-model="startDate" :max="endDate" locale="de-DE"></b-calendar>
        </div>
        <div>
          <label for="endDate">End Datum</label><br>
          <b-calendar name="endDate" v-model="endDate" :min="startDate" locale="de-DE"></b-calendar>
        </div>
      </div>
      <b-button variant="primary" class="mt-4" @click="saveSpecialResponse()">Erstellen</b-button>
    </div>
    <b-list-group class="mt-3">
      <b-list-group-item v-for="(item, index) in dataArray" :key="index">
        <!-- {{item}} -->
        <div class="d-flex" style="justify-content: space-between; align-items:center;">
          <div class="d-flex">
            <div class="mr-2">von: {{item.startDate.toDate().toLocaleDateString('de-DE')}}</div>
            <div class="mr-2">bis: {{item.endDate.toDate().toLocaleDateString('de-DE')}}</div>
          </div>
          <div><b-button variant="danger" v-b-modal.modal-1 @click="id=item.docId">Löschen</b-button></div>
        </div>
        <hr>
        <span v-html="item.text.replace(/(?:\r\n|\r|\n)/g, '<br>')"></span>
      </b-list-group-item>
    </b-list-group>
  <b-modal id="modal-1" title="Löschen?" @ok="deleteMessage()">
    <p class="my-4" >Möchtest du die Abwesenheitsnotiz Löschen? </p>
  </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { query, collection, getDocs, addDoc, deleteDoc, doc } from "firebase/firestore"
import {firestore} from "../plugins/firebase";

export default {
  name: 'SpecialResponse',
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

  data() {
    return {
      today: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      dataArray: undefined,
      absendenButton: false,
      message: {
          success: undefined,
          error: undefined
      },
      startDate: null, 
      endDate: null,
      text: null,
      id: null
    }
  },
  methods: {
    async loadData(){
      const q = await query(collection(firestore, "specialResponse"));
      const querySnapshot = await getDocs(q);
      const tempData = {}
      querySnapshot.forEach((doc) => {
        tempData[doc.id] = doc.data()
        tempData[doc.id].docId = doc.id
      })
      this.dataArray = tempData
    },
    async saveSpecialResponse(){
      const data = {
        startDate: new Date(this.startDate), 
        endDate: new Date(this.endDate),
        text: this.text
      }
      if(data.startDate > new Date(null) && data.endDate > new Date(null) && data.text){
          await addDoc(collection(firestore, "specialResponse"), data)
          .then(() => {
            this.message.success = 'Die Daten wurden erfolgreich gespeichert.'
            this.loadData();
            setTimeout(this.resetMessage, 3000)
          }).catch((error) => {
            this.message.error = 'Es ist ein Fehler aufgetreten.<br>'+error
            setTimeout(this.resetMessage, 3000)
          })
      } else {
        this.message.error = "Nicht alle Felder sind gefüllt.";
        setTimeout(this.resetMessage, 3000)
      }
    },
    resetMessage(){
      this.message.success = undefined
      this.message.error = undefined
    },
    deleteMessage() {
      deleteDoc(doc(firestore, '/specialResponse', `${this.id}`))
      .then(()=>{
        this.loadData();
      })
    }
  },
  mounted(){
    this.loadData()
  }
}
</script>

<style scoped>
  tr {
    cursor: pointer;
  }
  #personImage { 
    position: absolute; 
    right: 0px; 
    top: 0px;  
    padding: 5px; 
    border: 1px solid #d3d3d3; 
    border-radius: 5px; 
    margin: 16px; 
    width: 240px;
  }
  .printOnly{
    display: none;
  }
  .alert{
    z-index: 999999999;
  }
</style>
