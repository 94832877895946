import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from "./routes";
import VueHtmlToPaper from 'vue-html-to-paper';

// Font Awesome
import './fontawesome.js'

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import "@comlinedev/bootstrap4/dist/css/bootstrap.css";
import 'bootstrap-vue/dist/bootstrap-vue.css'

import moment from 'moment'

import { auth } from "./plugins/firebase"
import { onAuthStateChanged } from "firebase/auth"

const options = {
  name: '_blank',
  specs: [
    'fullscreen=no',
    'titlebar=no',
    'scrollbars=no'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    './assets/css/printStyle.css',
  ]
}

Vue.filter('convertDate', (value) => {
  return moment(value).format('DD.MM.YYYY')
})

Vue.use(VueHtmlToPaper, options);

Vue.use(BootstrapVue);

onAuthStateChanged(auth, async user => {
  await store.dispatch("fetchUser", user)
  new Vue({
    render: h => h(App),
    router,
    store
  }).$mount('#app')
  
})
