<template>
  <div style="display: inline-block;">
    <b-button variant="outline-dark" v-if="typeof propData.data == 'string' && url" :href="url" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Lebenslauf</b-button>
    <b-button variant="outline-dark" v-if="typeof propData.data == 'object'" :download="name" ref="downloadLink" href="#" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Lebenslauf</b-button>
  </div>
</template>

<script>
import { storage } from "../plugins/firebase"
import { ref as storageRef, getBlob } from "firebase/storage"

export default {
  name: 'Applications',
  data(){
    return{
      url: null,
      blob: null,
      name: null,
      propData: this.data
    }
  },
  props:{
    data: Object
  },
  methods: {
    async getImage(path){
      if (typeof path === 'object'){
        this.name = path.name
        await getBlob(storageRef(storage, path.path))
        .then(blob=>{
          this.$refs.downloadLink.href = URL.createObjectURL(blob);
        })
      } else {
        this.url = path
      }
      return
    }
  },
  mounted(){
    this.getImage(this.propData.data)
  }
}
</script>

<style scoped>
  #personImage { 
    position: absolute; 
    right: 0px; 
    top: 0px;  
    padding: 5px; 
    border: 1px solid #d3d3d3; 
    border-radius: 5px; 
    margin: 16px; 
    width: 240px;
  }
</style>
