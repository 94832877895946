<template v-if="this.user.loggedIn">
  <div class="container">
    <div v-if="message.success !== undefined">
      <div class="alert alert-success fixed-top text-center" role="alert">
        {{message.success}}
      </div>
    </div>
    <div v-if="message.error !== undefined">
      <div class="alert alert-danger fixed-top text-center" role="alert">
        {{message.error}}
      </div>
    </div>
    <div class="card">
      <div v-if="!dataArray" class="d-flex justify-content-center p-3" style="width: 100%;">
        <b-spinner label="Spinning"></b-spinner>
      </div>    
      <table class="table table-hover m-0" v-else>
        <thead>
          <tr>
            <th>Name</th>
            <th>Stelle</th>
            <th>Abteilung</th>
            <th>Eingegangen</th>
            <th></th>
          </tr>  
        </thead>
        <tbody v-for="(item, index) in dataArray" :key="index">
          <tr @click="createNew(index)"  v-if="item.archive === true">
            <td>{{salutation(item.salutation) + ' ' + item.firstname + ' ' + item.name}}</td>
            <td>{{item.jobOfferName}}</td>
            <td>{{item.department}}
              <template v-if="item?.trainee"><br><b-badge>Ausbildung</b-badge></template>
            </td>
            <td>{{convert(item.incoming.toDate())}}</td>
            <td><div v-if="item.reply === true"><font-awesome-icon :icon="['fas', 'reply']" /></div></td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-modal size="xl" tabindex="-1" role="dialog" v-model="formularModal" :title="jobOfferName" aria-hidden="true" hide-footer >
      <div v-if="personData == '' || personData == undefined" class="d-flex justify-content-center p-3" style="width: 100%;">
        <b-spinner label="Spinning"></b-spinner>
      </div>
      <div v-if="personData !== '' && personData !== undefined" class="" style="width: 100%;" >
        <div id="printMe">
          <h4 class="printOnly">{{jobOfferName}}</h4>
          <div>
            <p class="m-0"><b>Name</b></p>
            <p>{{salutation(personData.salutation) + ' ' + personData.firstname + ' ' + personData.name}}</p>
            <p class="m-0"><b>Geburtstag</b></p>
            <p>{{personData.birthday}}</p>
            <p class="m-0"><b>Adresse</b></p>
            <p class="m-0">{{personData.street}}</p>
            <p class="m-0">{{personData.zip + ' ' + personData.town}}</p>
            <p>{{personData.country}}</p>
            <p class="m-0"><b>Telefon</b></p>
            <p>{{personData.phonenumber}}</p>
            <p class="m-0"><b>E-Mail</b></p>
            <p>{{personData.email}}</p>
          </div>
          <div v-if="personData.image">
            <ImageBlob :data="{image: personData.image}"/>
          </div>
          <hr>
          <div>
            <p><b>Anschreiben</b></p>
            <div v-html="personData.coverLetter"></div>
          </div>
          <div class="printOnly">
            <hr>
            <p class="m-0"><b>Anlagen</b></p>
            <p v-if="personData.curriculumVitae !== ''" class="m-0"><small>Lebenslauf</small></p>
            <div v-for="(item, index) in personData.otherDocuments" :key="index">
              <p class="m-0"><small>{{item.name}} </small></p>
            </div>
          </div>  
        </div>
        <hr>
        <p><b>Datein</b></p>
        <ButtonBlob :data="{data: personData.curriculumVitae}"/>
        <DropDownButtonBlob :data="personData.otherDocuments" />
        <hr>
        <p><b>Aktionen</b></p>
        <b-button v-if="personData.email" variant="outline-primary" @click="replyMe(personId)" class="m-1"><font-awesome-icon :icon="['fas', 'reply']" /> Antworten</b-button>
        <b-button variant="outline-info" @click="generateReport()" class="m-1"><font-awesome-icon :icon="['fas', 'print']" /> Drucken</b-button>
        <b-button variant="outline-danger" @click="deleteMe(personId)" class="m-1"><font-awesome-icon :icon="['fas', 'trash']" /> Löschen</b-button>
        <div v-if="personData.reply">
          <hr>
          <p><b>Gesendete Nachrichten</b></p>

          <div class="accordion" role="tablist" v-for="(data, index) in personData.replyed" :key="index">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle="`collapse-${index}`" variant="outline-secondary">{{data.date}}</b-button>
              </b-card-header>
              <b-collapse :id="`collapse-${index}`" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text  v-html="data.message"></b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal size="xl" tabindex="-1" role="dialog" v-model="answerModal" aria-hidden="true" hide-footer >
      <div v-if="personData == '' || personData == undefined" class="d-flex justify-content-center p-3" style="width: 100%;">
        <b-spinner label="Spinning"></b-spinner>
      </div>
      <div v-if="personData !== '' && personData !== undefined" class="" style="width: 100%;">
        <p><b>{{personData.firstname + ' ' + personData.name}}</b></p>
        <p class="m-0">{{personData.street}}</p>
        <p class="m-0">{{personData.zip + ' ' + personData.town}}</p>
        <p>{{personData.country}}</p>
        <p>{{personData.email}}</p>
        <p>Flensburg, {{date}}</p>
        <p><b>Ihre Bewerbung</b></p>
        <p>Sehr {{correctSalutaion(personData.salutation)}} {{personData.name}},</p>

        <div>
          <b-form-select v-model="selected" :options="textTamplates"></b-form-select>
          <b-form-textarea
            id="textarea"
            placeholder="Schreibe einen Antworttext..."
            v-model="selected"
            rows="2"
            max-rows="6"
          ></b-form-textarea>
        </div><br>
        <p>Mit freundlichen Grüßen</p>
        <p>{{this.user.data.displayName}}</p>
        <hr>
        <b-button  variant="outline-primary" :disabled="absendenButton" @click="sendAnswer()" class="m-1"><font-awesome-icon :icon="['fas', 'paper-plane']" /> Absenden</b-button>
      </div>  
    </b-modal>
    <!-- <CheckUserName/> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { functions, firestore} from "../plugins/firebase"
import { query, collection, getDocs, doc, deleteDoc, updateDoc, where, orderBy } from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import moment from 'moment'
import ImageBlob from "./ImageBlob"
import ButtonBlob from "./ButtonBlob"
import DropDownButtonBlob from "./DropDownButtonBlob"

export default {
  name: 'Archive',
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  components: {
    ImageBlob,
    ButtonBlob,
    DropDownButtonBlob
  },
  data(){
    return{
      dataArray: undefined,
      message: {
                'success':undefined,
                'error':undefined
               },
      formularModal: false,
      answerModal: false,
      personData: undefined,
      jobOfferName: undefined,
      date: moment(new Date).format('DD.MM.YYYY'),
      personId: undefined,
      selected: undefined,
      curriculumVitaeURL: undefined,
      textTamplates: [
        { text: 'Wähle einen Antworttext...', value: null},
        { text: 'Empfangsbestätigung', value: 'vielen Dank für die Zusendung Ihrer Bewerbung.\nWir freuen uns über Ihr Interesse an einer Mitarbeit in unserem Unternehmen.\nDa wir ihre Unterlagen sorgfältig prüfen und mit der Leitung der Fachabteilung abstimmen, wird das Auswahlverfahren einige Zeit in Anspruch nehmen. Wir bitten Sie daher um etwas Geduld.\nDanach setzen wir uns so schnell wie möglich wieder mit Ihnen in Verbindung.\nIhre Bewerbungsunterlagen wurden elektronisch erfasst und werden entsprechend den Vorschriften des Bundesdatenschutzgesetzes behandelt.'},
        { text: 'Absage Mitbewerber', value: 'wir danken Ihnen für Ihre Bewerbung und dem damit gezeigten Interesse an unserem Unternehmen.\nLeider müssen wir Ihnen mitteilen, dass wir uns für einen Mitbewerber entschieden haben.\nWir bedauern, Ihnen keinen positiven Bescheid geben zu können und hoffen, Sie sehen dieses Schreiben nicht als Wertung Ihrer Qualifikationen an.\nFür Ihren weiteren beruflichen und privaten Lebensweg wünschen wir Ihnen alles Gute.'},
        { text: 'Absage Vorauswahl', value: 'wir danken Ihnen für Ihre Bewerbung und dem damit gezeigten Interesse an unserem Unternehmen.\nWir befinden uns derzeit in der Vorauswahl. Leider konnten wir Ihre Bewerbung nicht in die engere Wahl ziehen.\nWir bedauern, Ihnen keinen positiven Bescheid geben zu können und hoffen, Sie sehen dieses Schreiben nicht als Wertung Ihrer Qualifikationen an.\nWir wünschen Ihnen für Ihren weiteren Berufsweg alles Gute.'}
      ],
      absendenButton: false
    }
  },
  methods: {
    async loadData(){

      const q = await query(collection(firestore, "application"), orderBy("incoming"),  where('archive', '==', true));
      const querySnapshot = await getDocs(q);
      const tempData = {}
      querySnapshot.forEach((doc) => {
        console.log(doc.id)
        tempData[doc.id] = doc.data()
      })
console.log('here',tempData)
      this.dataArray = tempData
    },
    createNew(index){
      this.personData = this.dataArray[index]
      this.personId = index
      this.formularModal = !this.formularModal 
      this.jobOfferName = this.personData.jobOfferName
      this.curriculumVitaeURL = undefined
    },
    correctSalutaion(salutation){
      if(salutation === "Herr" || salutation === "Mr"){
        return 'geehrter Herr' 
      } else {
        return 'geehrte Frau'
      }
    },
    salutation(salutation){
      if(salutation === 'Mr'){
        return 'Herr'
      }
      else if(salutation === 'Mrs'){
        return 'Frau'
      }
      else {
        return salutation
      }
    },
    deleteMe(id){
      let index = id
      this.boxTwo = ''
        this.$bvModal.msgBoxConfirm(`Bist du sicher, dass du die Bewerbung von ${this.dataArray[index].firstname} Löschen möchtest.`, {
          title: 'Bitte bestätigen',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Löschen',
          cancelTitle: 'Behalten',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then((value) => {
            if(value){
              this.applicationModal = false
              deleteDoc(doc(firestore, '/application', `${id}`))
              .then(() => {
                setTimeout(this.resetMessage, 3000)
                this.loadData()
                this.message.success = 'Die Daten wurden erfolgreich gelöscht.'
                this.formularModal = null
              }).catch(error => {
                console.log(error)
                  this.message.error = 'Es ist ein Fehler aufgetreten.<br>'+error
                  setTimeout(this.resetMessage, 3000)
              })
            }
          })
          .catch(err => {
            console.log(err)
          })
    },
    replyMe(){
      this.answerModal = !this.answerModal 
    },
    resetMessage(){
      this.message.success = undefined
      this.message.error = undefined
    },
    sendAnswer(){
      if(this.selected !== undefined && this.selected !== null && this.selected !== ''){
        this.absendenButton  = true
        const anschreiben = '<p><b>'+this.personData.firstname + ' ' + this.personData.name+'</b></p>' +
                            '<p style="margin:0px;">'+this.personData.street+'</p>'+
                            '<p style="margin:0px;">'+this.personData.zip + ' ' + this.personData.town+'</p>'+
                            '<p style="margin:0px;">'+this.personData.country+'</p><br>'+
                            '<p>Flensburg, '+ this.date + '</p>'+
                            '<p><b>Ihre Bewerbung</b></p>'+
                            '<p>Sehr '+this.correctSalutaion(this.personData.salutation) + ' ' + this.personData.name+',</p>'

        const verabschiedung = '<br><p> Mit freundlichen Grüßen</p>'+this.user.data.displayName

        const anschreibenPlain = ''+this.personData.firstname + ' ' + this.personData.name+'\n\n' +
                                 ''+this.personData.street+'\n'+
                                 ''+this.personData.zip + ' ' + this.personData.town+'\n'+
                                 ''+this.personData.country+'\n\n'+
                                 'Flensburg, '+ this.date + '\n'+
                                 'Ihre Bewerbung\n\n'+
                                 'Sehr '+this.correctSalutaion(this.personData.salutation) + ' ' + this.personData.name+',\n\n'
        const verabschiedungPlain = '\n\nMit freundlichen Grüßen\n'+this.user.data.displayName
                    
        const mailData =  { 
          mail: this.personData.email,
          subject: 'Ihre Bewerbung - ComLine GmbH.',
          message: anschreiben + this.selected + verabschiedung,
          text: anschreibenPlain + this.selected + verabschiedungPlain,
          html: anschreiben + this.selected.replace(/(?:\r\n|\r|\n)/g, '<br>') + verabschiedung,
          user: this.user.data.email,
        }


        const sendMail = httpsCallable(functions, 'sendMail')
        sendMail(mailData)
        .then(res=> {
          console.log('mailresponse',res)
            this.message.success = 'Die E-Mail wurde erfolgreich versendet.' 
            this.answerModal = false
            this.selected = ''
            this.answered(this.personId)
            this.replyed(this.personId, mailData.html)
            this.absendenButton  = false
            setTimeout(this.resetMessage, 2000)
          return null
        })
        .catch(error=>{
          this.absendenButton  = false
          this.message.error = 'Die E-Mail konnte nicht versendet werden.' 
          setTimeout(this.resetMessage, 2000)
          console.warn(error)
          return null
        })


      } else {
        this.absendenButton  = false
        this.message.error = 'Bitte Wählen Sie einen Text aus oder Schreiben Sie einen Text.' 
        setTimeout(this.resetMessage, 3000)
      } 

    },
    generateReport () {
      this.$htmlToPaper('printMe');
    },
    answered(id){
      let data = this.personData
      data.reply = true
      updateDoc(doc(firestore, "application", id), data)
    },
    replyed(id, data){
      let allData = this.personData
      if(Array.isArray(allData?.replyed)){
        allData.replyed.push({date: this.date, message: data})
      } else {
        allData.replyed = [{date: this.date, message: data}]
      }
      updateDoc(doc(firestore, 'application', id), allData)
    },
    convert(data){
      var date = new Date(data)
      return(moment(date).format('DD.MM.YYYY'))
    },
  },
  mounted(){
    this.loadData()
  }
}
</script>

<style scoped>
  tr {
    cursor: pointer;
  }
  #personImage { 
    position: absolute; 
    right: 0px; 
    top: 0px;  
    padding: 5px; 
    border: 1px solid #d3d3d3; 
    border-radius: 5px; 
    margin: 16px; 
    width: 240px;
  }
  .printOnly{
    display: none;
  }
  .alert{
    z-index: 999999999;
  }
</style>
