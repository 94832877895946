<template v-if="this.user.loggedIn">
  <div class="container">
    <div v-if="message.success !== undefined">
      <div class="alert alert-success fixed-top text-center" role="alert">
        {{message.success}}
      </div>
    </div>
    <div v-if="message.error !== undefined">
      <div class="alert alert-danger fixed-top text-center" role="alert">
        {{message.error}}
      </div>
    </div>
    <div class="card">
      <div>
        <div v-if="!dataArray" class="d-flex justify-content-center p-3" style="width: 100%;">
          <b-spinner label="Spinning"></b-spinner>
        </div>    

        <table class="table table-hover m-0" v-else>
          <thead>
            <tr>
              <th>Bezeichnung</th>
              <th>Abteilung</th>
              <th>Typ</th>
              <th></th>
              <th>Status</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in dataArray" :key="index">
            <tr>
              <td style="width: 60%;">{{item.jobOfferName}}</td>
              <td style="width: 30%;">{{item.department}}</td>
              <td>{{item.type}}</td>
              <td class="mr-0" v-b-tooltip.hover.left="tooltip(item.status)"> <font-awesome-icon v-bind:class="[ item.status ]" :icon="['fas', 'circle']" /></td>
              <td v-b-tooltip.hover.left="tooltip(item.status)"> {{checkStatus(item.status)}} </td>
              <td> <a @click="editMe(index)"><font-awesome-icon :icon="['fas', 'edit']" /></a></td>
              <td> <a @click="deleteMe(index)"><font-awesome-icon :icon="['fas', 'trash']" /></a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <b-button class="m-2" variant="outline-success" @click="createNew"><font-awesome-icon :icon="['fa', 'plus']" /> Neu</b-button>

    <b-modal size="xl" tabindex="-1" role="dialog" v-model="formularModal" aria-hidden="true" hide-footer >
      <form>
        <div class="form-group">
          <label for="type">Stellenangebotstyp</label>
          <select class="form-control" name="type" v-model="formData.type">
            <option value="" disabled="">Auswählen</option>
            <option value="Vakanz">Vakanz</option>
            <option value="Ausbildung">Ausbildung</option>
            <option value="Duales Studium">Duales Studium</option>
          </select>
          <div class="alert alert-danger p-1 mt-1" v-if="checkFormData !== false && formData.type == ''">{{mssg}}</div>
        </div>

        <div class="form-group">
          <label for="type">Abteilung</label>
          <select class="form-control" name="department" v-model="formData.department">
            <option value="" disabled="">Auswählen</option>
            <option value="Apple">Apple</option>
            <option value="Audio/Video">Audio/Video</option>
            <option value="Buchhaltung">Buchhaltung</option>
            <option value="Einkauf">Einkauf</option>
            <option value="Lager">Lager</option>
            <option value="Logistik">Logistik</option>
            <option value="Marketing">Marketing</option>
            <option value="Microsoft">Microsoft</option>
            <option value="Peripherie">Peripherie</option>
            <option value="Personal">Personal</option>
            <option value="Produktmanagement">Produktmanagement</option>
            <option value="Retourenmanagement">Retourenmanagement</option>
            <option value="SmartLiving">SmartLiving</option>
            <option value="SMB">SMB</option>
            <option value="Softwareentwicklung">Softwareentwicklung</option>
            <option value="Technik">Technik</option>
            <option value="Vertrieb">Vertrieb</option>
          </select>
          <div class="alert alert-danger p-1 mt-1" v-if="checkFormData !== false && formData.department == ''">{{mssg}}</div>
        </div>
        <p>Bild</p>
        <div v-if="formData.imageUrl === undefined" class="mb-1">
          <b-btn @click="click1" variant="outline-dark" class="mr-2"><font-awesome-icon :icon="['fa', 'file-import']" />  Bild Hochladen</b-btn>
          <b-btn @click="selectImage" variant="outline-secondary" ><font-awesome-icon :icon="['fa', 'images']" />  Bild auswählen</b-btn>
          <input type="file" ref="Image"
            style="display: none"
            @change="previewImage" accept="image/x-png,image/jpeg">
        </div>

        <div v-if="uploadValue > 0 && uploadValue < 100">
          <p>Bild wird hochgeladfen.</p>
          <b-progress :value="uploadValue" :max="max" show-progress animated></b-progress>
        </div>

       <div v-if="imageUrl != undefined">
          <b-btn @click="chengeImage" variant="outline-dark" class="mb-2"><font-awesome-icon :icon="['fa', 'folder-open']" />  Bild ändern</b-btn><br>                     
          <img :src="imageUrl">
       </div>  
        <div class="form-group mt-2">
          <label for="type">Stelle</label>
          <input type="text" class="form-control" id="jobOfferName" v-model="formData.jobOfferName" placeholder="Webentwickler (m/w)">
          <div class="alert alert-danger p-1 mt-1" v-if="checkFormData !== false && formData.jobOfferName == ''">{{mssg}}</div>
        </div>

        <div class="form-group">
          <label for="type">Führungsverantwortung</label>
          <input type="text" class="form-control" id="managementResponsibility" v-model="formData.managementResponsibility" placeholder="Abteilungsleiter">
          <div class="alert alert-danger p-1 mt-1" v-if="checkFormData !== false && formData.managementResponsibility == ''">{{mssg}}</div>
        </div>
        
        <div class="form-group">
          <label for="type">Arbeitszeit</label>
          <input type="text" class="form-control" id="workingTime" v-model="formData.workingTime" placeholder="Vollzeit">
          <div class="alert alert-danger p-1 mt-1" v-if="checkFormData !== false && formData.workingTime == ''">{{mssg}}</div>
        </div>

        <div class="form-group">
          <label for="type">Befristung</label>
          <input type="text" class="form-control" id="limited" v-model="formData.limited" placeholder="Keine">
          <div class="alert alert-danger p-1 mt-1" v-if="checkFormData !== false && formData.limited == ''">{{mssg}}</div>
        </div>

        <div class="form-group">
          <label for="type">Beginn der Tätigkeit</label>
          <input type="text" class="form-control" id="workingStart" v-model="formData.workingStart" placeholder="Ab sofort">
          <div class="alert alert-danger p-1 mt-1" v-if="checkFormData !== false && formData.workingStart == ''">{{mssg}}</div>
        </div>

        <div v-if="formData.type === 'Ausbildung'" class="form-group">
          <label for="type">Wenn nicht verfügbar (optional)</label>
          <input type="text" class="form-control" id="unavailableText" v-model="formData.unavailableText" placeholder="Derzeit nicht verfügbar" maxlength="25">
        </div>

        <quillEditor style="width: 100%;"
          :options="quillConfig"
          v-model="formData.description"
        ></quillEditor>

        <b-button class="m-2" variant="outline-warning" v-if="formData.status !== 'entwurf'" @click.prevent="saveMe('entwurf')"><font-awesome-icon :icon="['fa', 'folder-open']" /> Entwurf</b-button>
        <b-button class="m-2" variant="outline-success" v-if="formData.status !== 'online'" @click.prevent="saveMe('online')"><font-awesome-icon :icon="['fa', 'globe-europe']" /> Veröffentlichen</b-button>
        <b-button class="m-2" variant="outline-success" v-if="formData.status !== 'unavailable'" @click.prevent="saveMe('unavailable')"><font-awesome-icon :icon="['fa', 'globe-europe']" /> Veröffentlichen (nicht verfügbar)</b-button>
        <b-button class="m-2" variant="outline-primary" @click.prevent="saveMe('save')"><font-awesome-icon :icon="['fa', 'save']" /> Speichern</b-button>
        <b-button class="m-2" variant="outline-danger" @click="formularModal = !formularModal"><font-awesome-icon :icon="['fa', 'times']" /> Abbrechen</b-button>

      </form>
    </b-modal>

    <b-modal size="xl" tabindex="-1" role="dialog" v-model="selectImageModal" aria-hidden="true" hide-footer >
      <div class=" d-flex flex-wrap">
      <div v-for="(item, index) in listAllImages" :key="index" class="">
        <div class="p-2">
          <img class="selectImage" :src="item" @click="selectMeAsImage(item)" >
                  </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { storage, firestore } from "../plugins/firebase"
import { query, collection, getDocs, setDoc, doc, deleteDoc, addDoc } from "firebase/firestore"
import { ref as storageRef, uploadBytesResumable, listAll, getDownloadURL } from "firebase/storage"

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Quill from 'quill'
import { quillEditor } from 'vue-quill-editor'
import VuePlainClipboard from 'vue-quill-plain-clipboard'
// import CheckUserName from "./CheckUserName"

Quill.register('modules/clipboard', VuePlainClipboard, true)

export default {
  name: 'JobOffers',
  components: {
   quillEditor,
  //  CheckUserName
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  data(){
    return{
      listAllImages: [],
      uploadValue: 0,
      imageUrl: undefined,
      dataArray: {},
      checkFormData: false,
      formularModal: false,
      selectImageModal: false,
      message: {
                'success':undefined,
                'error':undefined
                },
      formData: { 
        status: "entwurf", 
        type: "",
        department: "",
        jobOfferName: "",
        managementResponsibility: "",
        workingTime: "",
        limited: "",
        workingStart: "",
        description: "",
        imageData: null,
        imageUrl: undefined,
        unavailableText: "",
      },
      formDataId: null,
      mssg: 'Bitte ausfüllen.',
      quillConfig:{
        modules: {
          toolbar: [
            [
              'bold', 
              'italic'
            ],
            [
              { 'list': 'ordered'}, 
              { 'list': 'bullet' }
            ],
            [
              { 'indent': '-1'}, 
              { 'indent': '+1' }
            ]
          ]
        }
      }
    }
  },
  methods: {
    async loadData(){
      const q = await query(collection(firestore, "jobOffers"));
      const querySnapshot = await getDocs(q);
      const tempData = {}
      querySnapshot.forEach((doc) => {
        console.log(doc.id)
        tempData[doc.id] = doc.data()
      })
      this.dataArray = tempData
      console.log(this.dataArray)
    },
    async saveMe(status){
      if(!this.checkData()){
        if(status !== "save" && this.formData.status) this.formData.status = status;
        else if (!this.formData.status) this.formData.status = "entwurf";

        if (this.formDataId === null) {

          await addDoc(collection(firestore, "jobOffers"), this.formData)
          .then(() => {
            this.message.success = 'Die Daten wurden erfolgreich gespeichert.'
            setTimeout(this.resetMessage, 3000)
            this.clear
            this.formDataId = null
            this.formularModal = null
            this.loadData()
          }).catch((error) => {
            this.message.error = 'Es ist ein Fehler aufgetreten.<br>'+error
            setTimeout(this.resetMessage, 3000)
          });
        } else { 

          setDoc(doc(firestore, `jobOffers`, this.formDataId), this.formData)
          .then(() => {
            this.message.success = 'Die Daten wurden erfolgreich gespeichert.'
            setTimeout(this.resetMessage, 3000)
            this.clear
            this.formDataId = null
            this.formularModal = null
          }).catch((error) => {
            this.message.error = 'Es ist ein Fehler aufgetreten.<br>'+error
            setTimeout(this.resetMessage, 3000)
          });
        }
      }
    },
    editMe(id){
      console.log(id)
      this.formData = this.dataArray[id]
      this.imageUrl = this.dataArray[id].imageUrl
      this.formDataId = id
      this.formularModal = true
    },
    deleteMe(id) {
        this.boxTwo = ''
        this.$bvModal.msgBoxConfirm(`Bist du sicher, dass du ${this.dataArray[id].jobOfferName} Löschen möchtest.`, {
          title: 'Bitte bestätigen',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Löschen',
          cancelTitle: 'Behalten',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then((value) => {
            if(value){
            deleteDoc(doc(firestore, `jobOffers`, `${id}`))
            .then(()=>{
               this.loadData()
            })
            }
          })
          .catch(err => {
            console.log(err)
          })
    },
    createNew(){
      this.formularModal = !this.formularModal 
      this.checkFormData = false
      this.clear()
    },
    tooltip(status){
      if(status === 'online'){
        return 'Wird Online angezeigt, das Bewerben ist möglich.'
      }
      if(status === 'unavailable'){
        return 'Wird Online ausgegraut angezeigt, das Bewerben ist nicht möglich.'
      }
      if(status === 'entwurf'){
        return 'Wird Online nicht angezeigt.'
      }
    },
    checkStatus(status){
      const checkedStatus = status === 'unavailable' ? 'nicht Verfügbar' : status;
      return checkedStatus
    },
    clear(){
      this.formData = { 
                status: "entwurf", 
                type: "",
                department: "",
                jobOfferName: "",
                managementResponsibility: "",
                workingTime: "",
                limited: "",
                workingStart: "",
                description: ""
              }
      this.imageUrl = undefined
    },
    checkData(){
      if(this.formData.type == '' || 
         this.formData.department == '' ||
         this.formData.jobOfferName == '' ||
         this.formData.managementResponsibility == '' ||
         this.formData.workingTime == '' ||
         this.formData.limited == '' ||
         this.formData.workingStart == '' )
      {
        this.checkFormData = true
        return true 
      }
    },
    resetMessage(){
      this.message.success = undefined
      this.message.error = undefined
    },
    click1() {
      this.$refs.Image.click()   
    },
    previewImage(event) {
      this.uploadValue=0;
      this.formData.imageUrl=undefined;

      const upload = uploadBytesResumable(storageRef(storage, 'jobOffers/'+Date.now()), event.target.files[0]);

      upload.on(`state_changed`, 
        snapshot => {
          this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        }, 
        error => {console.log(error.message)},
        ()=>{
          this.uploadValue=100;        
          getDownloadURL(upload.snapshot.ref)
          .then(url=>{
            this.uploadValue = 0;
            this.formData.imageUrl = url
            this.imageUrl = url
            console.log(url)
          })
      });
    },
    chengeImage(){
      this.imageUrl = undefined
      this.formData.imageUrl = undefined
    },
    selectImage(){
      this.selectImageModal = true
      this.loadAllImages()
    },
    loadAllImages(){
      this.listAllImages = []
      const _this = this;

      listAll(storageRef(storage, "jobOffers"))
      .then(function(result) {
        result.items.forEach(function(imageRef) {
          displayImage(imageRef);
          // console.log(imageRef)
        });
      }).catch(function(error) {
        console.log(error)
      });

      function displayImage(imageRef) {
        getDownloadURL(imageRef).then(function(url) {
          console.log(url)
          _this.listAllImages.push(url)
        }).catch(function(error) {
          console.log(error)
        });
      }
    },
    selectMeAsImage(item){
      this.formData.imageUrl = item
      this.imageUrl = item
      this.selectImageModal = false
    },
  },
  beforeMount() {
    this.loadData()
  }
}
</script>

<style scoped>

a { 
  color: #000000;
}

a:hover { 
  color: #646464 !important;
  cursor: pointer;
}

.online { 
  color: green;
}

.unavailable { 
  color: gray;
}

.entwurf { 
  color: orange;
}

.selectImage {
  width: 100%;
  max-width: 300px;
  margin: 20px;
  cursor: pointer; 
}

.selectImage:hover {
  max-width: 310px;
  margin: 20px 15px;  
  transition: ease-in 0.1s;
}
</style>
