import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyCJJEfMiUFQYCkA82bcYgbP_ocQl5Tb3Co",
  authDomain: "clgmbh-bewerberportal.firebaseapp.com",
  databaseURL: "https://clgmbh-bewerberportal.firebaseio.com",
  projectId: "clgmbh-bewerberportal",
  storageBucket: "clgmbh-bewerberportal.appspot.com",
  messagingSenderId: "217968092332",
  appId: "1:217968092332:web:42284e3bb5da2e25766b09"
};

const region = 'europe-west3';

location.hostname === 'localhost' && (self.FIREBASE_APPCHECK_DEBUG_TOKEN = true)

const firebase = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(firebase, {
  provider: new ReCaptchaV3Provider('6LcvRmggAAAAANJNdhYzM46dn-kmxxaXu2zXyMTE'),
  isTokenAutoRefreshEnabled: true
});


const auth = getAuth(firebase);
const firestore = getFirestore(firebase);
const functions = getFunctions(firebase, region);
const storage = getStorage(firebase);

if (location.hostname === 'localhost') {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectStorageEmulator(storage, "localhost", 9199);
}

export {firebase, auth, functions, storage, firestore, region, appCheck} 