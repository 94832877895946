// Vue
import Vue from 'vue'

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { 
  faCog,
  faCogs,
  faSignOut,
  faTrash,
  faEdit,
  faPlus,
  faInfoCircle,
  faCircle,
  faChartLine,
  faTruck,
  faCircleNotch,
  faSortDown,
  faSortUp,
  faSave,
  faTimes,
  faGlobeEurope,
  faFolderOpen,
  faReply,
  faDownload,
  faArchive,
  faPaperPlane,
  faPrint,
  faFileImport,
  faImages,
  faEnvelope,
  faKey
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faCog,
  faCogs,
  faSignOut,
  faTrash,
  faEdit,
  faPlus,
  faInfoCircle,
  faCircle,
  faChartLine,
  faTruck,
  faCircleNotch,
  faSortDown,
  faSortUp,
  faSave,
  faTimes,
  faGlobeEurope,
  faFolderOpen,
  faReply,
  faDownload,
  faArchive,
  faPaperPlane,
  faPrint,
  faFileImport,
  faImages,
  faEnvelope,
  faKey
)
Vue.component('font-awesome-icon', FontAwesomeIcon)